import React from "react";
import "./style.css";

const Box = () => {
  return (
    <div className="box">
	<div className="blinking-green"></div>
      <div className="frame-wrapper">
        <div className="frame">
          <header className="header">
            <a href="https://www.rezior.com"><img
              className="img"
              alt="Rezior"
              src="https://generation-sessions.s3.amazonaws.com/807287c623a199382c199a1c72af1c95/img/frame.svg"
            /></a>
            <div className="div">
              <div className="menu-items">
                <a href="https://www.rezior.com/integration.html" style={{"text-decoration":"none"}}><div className="text-wrapper">POS Integration</div></a>
              </div>
              <div className="button-group">
                <div className="action-button">
				{/*<div className="button">
                    <a href="https://documenter.getpostman.com/view/882924/2s946k5AWt#1621bec7-bd08-40fe-b205-63eeaee500d2"  style={{"text-decoration":"none"}}><div className="text-wrapper-2">Get Started</div></a>
				</div>*/}
                </div>
                
                  <b>LIVE</b> <span class="blink_me"></span>
                
              </div>
            </div>
          </header>
        </div>
      </div>
    </div>
  );
};

export default Box;
