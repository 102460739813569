import React from "react";
import Form from "./Form";
import Box from "./Box";
import Logo from "./logo.svg"
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <div>
	{/*<nav className="navbar navbar-light bg-light">
		  <div className="container-fluid">
			<a className="navbar-brand mb-4" href="#">
			  <img src={Logo} alt="" width="60" height="30" className="d-inline-block align-text-top"/>
			  &nbsp;&nbsp;&nbsp;Rezior Connect Test device on Cloud
			</a>
		  </div>
	</nav>*/}
	
	{/*<nav className="navbar navbar-light bg-light">
		<div className="container-fluid">
			<a className="navbar-brand mb-3" href="#">
			  <img src={Logo} className="d-inline-block align-text-top"/>
			</a>
		<div className="fw-bold text-success">Device on Cloud &nbsp;<img height="30" width="80" src="https://i.gifer.com/XVo3.gif"/></div>	 
		</div>
	</nav>*/}
	<Box/>
           
      <Form/>
    </div>
  );
}

export default App;