import React, { useState } from "react";
import axios from "axios";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Arch from "./is.png";


	
	
const styledropdown={
"font-family": "Inter",
"font-size": "16px",
"font-weight": "400",
"line-height": "24px",
"letter-spacing": "0px",
"text-align": "left"

};


const animatedComponents = makeAnimated();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  zIndex: 10,
  overflow:'scroll',
  p: 4,
};

const val1=[{
    "basket": [
        {
            "code": "037000585930",
            "unit": "item", 
            "quantity": "2",
            "price": 500
        }
    ],
    "coupons": [
        "8112001222229999010443456",
		"8112001222229999090441235"
    ]
}];

const val2=[{
    "basket": [
        {
            "code": "037000475873",
            "unit": "item", 
            "quantity": "2",
            "price": 1200
        }
    ],
    "coupons": [
        "8112001222229999020443456",
		"8112001222229999090441236"
    ]
}];

const val3=[{
    "basket": [
        {
            "code": "037000838975",
            "unit": "item", 
            "quantity": "5",
            "price": 800
        },
        {
            "code": "037000416005",
            "unit": "item", 
            "quantity": "1",
            "price": 1500
        },
        {
            "code": "037000404217",
            "unit": "item", 
            "quantity": "2",
            "price": 1200
        }
    ],
    "coupons": [
        "8112001222229999030443456",
		"8112001222229999090441236"
    ]
}];

const val4=[{
    "basket": [
        {
            "code": "037000567332",
            "unit": "item", 
            "quantity": "2",
            "price": 500
        },
        {
            "code": "037000549345",
            "unit": "item", 
            "quantity": "1",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999040443456",
		"8112001222229999090441236"
    ]
}];


const val5=[{
    "basket": [
        {
            "code": "037000738114",
            "unit": "item", 
            "quantity": "2",
            "price": 500
        },
        {
            "code": "037000454014",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999050443456",
		"8112001222229999090441236"
    ]
}];

const val6=[{
    "basket": [
        {
            "code": "037000453970",
            "unit": "item", 
            "quantity": "2",
            "price": 1000
        },
        {
            "code": "037000418252",
            "unit": "item", 
            "quantity": "3",
            "price": 500
        },
        {
            "code": "037000585930",
            "unit": "item", 
            "quantity": "2",
            "price": 1500
        }
    ],
    "coupons": [
        "8112001222229999960448822",
        "8112001222229999010443456"
    ]
}];

const val7=[{
    "basket": [
        {
            "code": "037000418238",
            "unit": "item", 
            "quantity": "13",
            "price": 1900
        },
        {
            "code": "037000416005",
            "unit": "item", 
            "quantity": "2",
            "price": 1500
        }
    ],
    "coupons": [
        "8112001222229999070443456",
		"8112001222229999090441235",
		"8112001222229999090441236"
    ]
}];

const val8=[{
    "basket": [
        {
            "code": "037000416470",
            "unit": "item", 
            "quantity": "5",
            "price": 500
        },
        {
            "code": "037000413127",
            "unit": "item", 
            "quantity": "2",
            "price": 200
        },
        {
            "code": "037000418221",
            "unit": "item", 
            "quantity": "2",
            "price": 100
        }
    ],
    "coupons": [
        "8112001222229999080443456"
    ]
}];

const val9=[{
    "basket": [
        {
            "code": "037000418122",
            "unit": "item", 
            "quantity": "5",
            "price": 500
        },
        {
            "code": "037000416005",
            "unit": "item", 
            "quantity": "2",
            "price": 900
        },
        {
            "code": "037000404217",
            "unit": "item", 
            "quantity": "2",
            "price": 1500
        }
    ],
    "coupons": [
        "8112001222229999090441234",
		"8112001222229999090441235",
		"8112001222229999090441236"
    ]
}];

const val10=[{
    "basket": [
        {
            "code": "037000402138",
            "unit": "item", 
            "quantity": "2",
            "price": 1500
        },
        {
            "code": "037000402121",
            "unit": "item", 
            "quantity": "2",
            "price": 500
        },
        {
            "code": "037000404194",
            "unit": "item", 
            "quantity": "5",
            "price": 1700
        }
    ],
    "coupons": [
        "8112001222229999100443456"
		
    ]
}];


const val11=[{
    "basket": [
		{
            "code": "037000402107",
            "unit": "item", 
            "quantity": "2",
            "price": 500
        },
        {
            "code": "037000402091",
            "unit": "item", 
            "quantity": "2",
            "price": 1000
        },
        {
            "code": "037000823063",
            "unit": "item", 
            "quantity": "3",
            "price": 700
        }
    ],
    "coupons": [
        "8112001222229999110443456"
    ]
}];

const val12=[{
    "basket": [
        {
            "code": "037000810162",
            "unit": "item", 
            "quantity": "1",
            "price": 100
        },
        {
            "code": "037000809685",
            "unit": "item", 
            "quantity": "4",
            "price": 1200
        },
        {
            "code": "037000810995",
            "unit": "item", 
            "quantity": "6",
            "price": 900
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999120443456",
		"8112001222229999030441235"
    ]
}];

const val13=[{
    "basket": [
        {
            "code": "037000752684",
            "unit": "item", 
            "quantity": "1",
            "price": 1100
        },
        {
            "code": "037000752622",
            "unit": "item", 
            "quantity": "2",
            "price": 100
        },
        {
            "code": "037000739753",
            "unit": "item", 
            "quantity": "6",
            "price": 900
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999130443456",
		"8112001222229999030441235"
    ]
}];

const val14=[{
    "basket": [
        {
            "code": "037000885740",
            "unit": "item", 
            "quantity": "1",
            "price": 600
        },
        {
            "code": "037000885733",
            "unit": "item", 
            "quantity": "2",
            "price": 100
        },
        {
            "code": "037000875529",
            "unit": "item", 
            "quantity": "3",
            "price": 900
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999140443456",
		"8112001222229999030441235"
    ]
}];

const val15=[{
    "basket": [
        {
            "code": "037000893776",
            "unit": "item", 
            "quantity": "6",
            "price": 300
        },
        {
            "code": "037000752622",
            "unit": "item", 
            "quantity": "2",
            "price": 100
        }
    ],
    "coupons": [
        "8112001222229999150443456",
		"8112001222229999030441235"
    ]
}];

const val16=[{
    "basket": [
        {
            "code": "037000893769",
            "unit": "item", 
            "quantity": "3",
            "price": 200
        },
        {
            "code": "037000893752",
            "unit": "item", 
            "quantity": "2",
            "price": 400
        },
        {
            "code": "037000739753",
            "unit": "item", 
            "quantity": "6",
            "price": 900
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999160443456",
		"8112001222229999030441235"
    ]
}];

const val17=[{
    "basket": [
        {
            "code": "037000893745",
            "unit": "item", 
            "quantity": "1",
            "price": 500
        },
        {
            "code": "037000874263",
            "unit": "item", 
            "quantity": "2",
            "price": 200
        },
        {
            "code": "037000739753",
            "unit": "item", 
            "quantity": "6",
            "price": 900
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999170443456",
		"8112001222229999030441235"
    ]
}];

const val18=[{
    "basket": [
        {
            "code": "037000874478",
            "unit": "item", 
            "quantity": "1",
            "price": 500
        },
        {
            "code": "037000874270",
            "unit": "item", 
            "quantity": "2",
            "price": 150
        },
        {
            "code": "037000885825",
            "unit": "item", 
            "quantity": "1",
            "price": 300
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999180443456",
		"8112001222229999030441235"
    ]
}];

const val19=[{
    "basket": [
        {
            "code": "037000985136",
            "unit": "item", 
            "quantity": "1",
            "price": 100
        },
        {
            "code": "037000985099",
            "unit": "item", 
            "quantity": "2",
            "price": 100
        },
        {
            "code": "037000885771",
            "unit": "item", 
            "quantity": "6",
            "price": 100
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999190443456",
		"8112001222229999030441235"
    ]
}];

const val20=[{
    "basket": [
        {
            "code": "037000885757",
            "unit": "item", 
            "quantity": "1",
            "price": 100
        },
        {
            "code": "037000959809",
            "unit": "item", 
            "quantity": "2",
            "price": 100
        },
        {
            "code": "037000874225",
            "unit": "item", 
            "quantity": "6",
            "price": 50
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999200443456",
		"8112001222229999030441235"
    ]
}];


const val21=[{
    "basket": [
        {
            "code": "037000810162",
            "unit": "item", 
            "quantity": "1",
            "price": 100
        },
        {
            "code": "037000809685",
            "unit": "item", 
            "quantity": "4",
            "price": 1200
        },
        {
            "code": "037000810995",
            "unit": "item", 
            "quantity": "6",
            "price": 900
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999210443456",
		"8112001222229999030441235"
    ]
}];

const val22=[{
    "basket": [
        {
            "code": "037000875864",
            "unit": "item", 
            "quantity": "1",
            "price": 100
        },
        {
            "code": "037000752622",
            "unit": "item", 
            "quantity": "2",
            "price": 100
        },
        {
            "code": "037000739753",
            "unit": "item", 
            "quantity": "6",
            "price": 200
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229999220443456",
		"8112001222229999270441235"
    ]
}];

const val23=[{
    "basket": [
        {
            "code": "037000810162",
            "unit": "item", 
            "quantity": "1",
            "price": 100
        },
        {
            "code": "037000809685",
            "unit": "item", 
            "quantity": "4",
            "price": 1200
        },
        {
            "code": "037000810995",
            "unit": "item", 
            "quantity": "6",
            "price": 900
        },
		{
            "code": "037000809680",
            "unit": "item", 
            "quantity": "4",
            "price": 200
        },
		{
            "code": "037000585930",
            "unit": "item", 
            "quantity": "2",
            "price": 500
        }
    ],
    "coupons": [
        "8112001222229999210443456",
		"8112001222229999010443456"
    ]
}];


const val24=[{
    "basket": [
        {
            "code": "037000874539",
            "unit": "item", 
            "quantity": "3",
            "price": 1200
        },
        {
            "code": "037000875635",
            "unit": "item", 
            "quantity": "4",
            "price": 100
        }
       
    ],
    "coupons": [
        "8112001222229999250445555"
    ]
}];

const val25=[{
    "basket": [
        {
            "code": "037000875642",
            "unit": "item", 
            "quantity": "7",
            "price": 900
        }
    ],
    "coupons": [
        "9999000099990"
    ]
}];

const val26=[{
    "basket": [
        {
            "code": "037000875642",
            "unit": "item", 
            "quantity": "7",
            "price": 900
        },
        {
            "code": "037000875864",
            "unit": "item", 
            "quantity": "1",
            "price": 200
        }
       
    ],
    "coupons": [
        "9999000099990"
    ]
}];

const val27=[{
    "basket": [
        {
            "code": "037000875642",
            "unit": "item", 
            "quantity": "7",
            "price": 2000
        }
    ],
    "coupons": [
        "8112001222229999270445553"
    ]
}];

const val28=[{
    "basket": [
        {
            
			"plu":"3022",
            "unit": "kg", 
            "quantity": "5",
            "price": 1600
        }
    ],
    "coupons": [
        "8112001222229999280445553"
    ]
}];

const val29=[{
    "basket": [
        {
            "code": "037000874720",
            "unit": "item", 
            "quantity": "3",
            "price": 1200
        }
       
    ],
    "coupons": [
        "9999111199991"
    ]
}];

const val30=[{
    "basket": [
        {
            "code": "037000874539",
            "unit": "item", 
            "quantity": "1",
            "price": 1200
        },
        {
            "code": "037000875635",
            "unit": "item", 
            "quantity": "4",
            "price": 100
        }
       
    ],
    "coupons": [
        "8112001222229999250445555"
    ]
}];


const val31=[{
    "basket": [
        {
            "code": "073390003937",
            "unit": "item",
            "quantity": "1",
            "price": 1200
        },
        {
            "code": "037000875635",
            "unit": "item",
            "quantity": "4",
            "price": 100
        }

    ],
    "coupons": [
        "8112001222229900010445555"
    ]
}];

const val32=[{
    "basket": [
        {
            "code": "022000016065",
            "unit": "item",
            "quantity": "2",
            "price": 1200
        },
        {
            "code": "022000016690",
            "unit": "item",
            "quantity": "2",
            "price": 800
        },
        {
            "code": "022000019080",
            "unit": "item",
            "quantity": "3",
            "price": 100
        }

    ],
    "coupons": [
        "8112001222229900020445555"
    ]
}];

const val33=[{
    "basket": [
        {
            "code": "012546610438",
            "unit": "item",
            "quantity": "1",
            "price": 1200
        },
        {
            "code": "012546917698",
            "unit": "item",
            "quantity": "4",
            "price": 800
        },
        {
            "code": "019000085030",
            "unit": "item",
            "quantity": "3",
            "price": 100
        }

    ],
    "coupons": [
        "8112001222229900030445555"
    ]
}];

const val34=[{
    "basket": [
        {
            "code": "012546009454",
            "unit": "item",
            "quantity": "1",
            "price": 1200
        },
        {
            "code": "012546011600",
            "unit": "item",
            "quantity": "2",
            "price": 800
        },
        {
            "code": "012546012706",
            "unit": "item",
            "quantity": "6",
            "price": 100
        }

    ],
    "coupons": [
        "8112001222229900040445555"
    ]
}];

const val35=[{
    "basket": [
        {
            "code": "600699661027",
            "unit": "item",
            "quantity": "1",
            "price": 1200
        }
    ],
    "coupons": [
        "8112001222229900053445566765"
    ]
}];

const val36=[{
    "basket": [
        {
            "code": "022000120304",
            "unit": "item",
            "quantity": "1",
            "price": 1200
        },
        {
            "code": "022000159359",
            "unit": "item",
            "quantity": "1",
            "price": 200
        }
    ],
    "coupons": [
        "8112001222229900060445555"
    ]
}];

const val37=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "1",
            "price": 1200
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900070445555"
    ]
}];

const val38=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "3",
            "price": 1200
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900080445555"
    ]
}];

const val39=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "3",
            "price": 1200
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900090445555"
    ]
}];

const val40=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "3",
            "price": 1200
        }

    ],
    "coupons": [
        "8112001222229900100445555"
    ]
}];

const val41=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "3",
            "price": 180
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900110445555"
    ]
}];

const val42=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "3",
            "price": 180
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900120445555"
    ]
}];

const val43=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "3",
            "price": 180
        }

    ],
    "coupons": [
        "8112001222229900130445555"
    ]
}];

const val44=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "3",
            "price": 180
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900140445555"
    ]
}];

const val45=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "5",
            "price": 180
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900150445555"
    ]
}];

const val46=[{
    "basket": [
        {
            "code": "040000534402",
            "unit": "item",
            "quantity": "5",
            "price": 1800
        },
        {
            "code": "041420016332",
            "unit": "item",
            "quantity": "1",
            "price": 200
        },
        {
            "code": "041420102622",
            "unit": "item",
            "quantity": "1",
            "price": 600
        }
    ],
    "coupons": [
        "8112001222229900160445555"
    ]
}];

const options = [
          { value: val31[0], label: 'Buy $1.00 of Item A, get $0.50 off item(s)' },
          { value: val32[0], label: 'Buy 1 item A AND 2 item B AND 3 item C, get 1 item A free (APRC = 1; SVAT = 0)' },
          { value: val33[0], label: 'Buy 1 item A AND 2 item B AND 3 item C, get 2 item B free (APRC = 1; SVAT = 1)' },
          { value: val34[0], label: 'Buy 1 item A AND 2 item B AND 3 item C, get 3 item C free (APRC = 1; SVAT = 2)' },
          { value: val35[0], label: 'Buy 1 Item A, get $0.50 off item(s)' },
          { value: val36[0], label: 'Buy 1 Item A, Get 1 Item B Free (APRC = 1; SVAT = 1)' },
          { value: val37[0], label: 'Buy 1 of Item A AND 1 of Item B AND 1 of item C, get $2.00 off item A (APRC = 1; SVAT = 0)' },
          { value: val38[0], label: 'Buy 2 Item A AND 1 Item B OR 1 Item C, get 1 Item A Free (APRC = 2; SVAT = 0)' },
          { value: val39[0], label: 'Buy 2 item A AND 1 item B, get $2.00 off item A (ARPC = 1; SVAT = 0)' },
          { value: val40[0], label: 'Buy 2 Item A, get 1 Item A free' },
          { value: val41[0], label: 'Buy 2 Item A, get 1 Item A free (up to $1.99)' },
          { value: val42[0], label: 'Buy 3 Item A AND 1 Item B OR 1 Item C, get $0.50 off item A (APRC = 2; SVAT = 0)' },
          { value: val43[0], label: 'Buy 3 Item A OR 1 Item B OR 1 Item C, get $0.50 off item(s) (APRC = 0)' },
          { value: val44[0], label: 'Buy 3 Item A OR 1 Item B, get $0.50 off item(s) (APRC = 0)' },
          { value: val45[0], label: 'Buy 3 Item A, get 2 Item A free' },
          { value: val46[0], label: 'Get 5.00 Off Your Transaction when you spend $50.00 or more' }
];


/*
const options = [
	  { value: val1[0], label: 'Save $1.00 when you buy 1 bottle of ketchup' },
	  { value: val2[0], label: 'Buy one bottle of ketchup, get one free (no max value)' },
	  { value: val3[0], label: 'Buy one bottle of ketchup, get one free ( up to max value)' },
	  { value: val4[0], label: 'Buy 1 bottle of ketchup, Get 1 Free bottle of mustard' },
	  { value: val5[0], label: 'Buy 2 bottles of ketchup or 4 bottles of mustard, Save $1' },
	  { value: val6[0], label: 'Buy 2 bottles of ketchup and 3 bottles of mustard, get $1 Off + Save $1.00 when you buy 1 bottle of honey' },
	  { value: val7[0], label: 'Get 3 bottles of soda free when you buy 10 bottles' },
	  { value: val8[0], label: 'Buy 5 bags of chips, AND 2 jars of dip or 2 bottles of soda, Get $2 Off' },
	  { value: val9[0], label: 'Buy 5 bags of chips, AND 2 jars of dip or 2 bottles of soda, Get 1 bag of chips Free' },
	  { value: val10[0], label: 'Buy 1 bag of chips OR 2 jars of dip OR 5 bottles of soda, Get $2 Off' },
	  { value: val11[0], label: 'Buy 1 bag of chips and 2 jars of dip and 3 bottles of soda, Get 1 bag of chips free' },
	  { value: val12[0], label: 'Buy 1 bag of chips and 2 jars of dip and 3 bottles of soda, Get 2 jars of dip free' },
	  { value: val21[0], label: 'Get 5.00 Off Your Transaction when you spend $50.00 or more' },
	  { value: val23[0], label: 'Get 5.00 Off Your Transaction when you spend $50.00 or more + Save $1.00 when you buy 1 bottle of ketchup' },
	  { value: val13[0], label: 'Buy 1 bag of chips and 2 jars of dip and 3 bottles of soda, Get 3 bottles of soda free' },
	  { value: val14[0], label: 'Buy 1 bag of chips and 2 jars of dip and 3 bottles of soda, Get $3 Off' },
	  { value: val15[0], label: 'Get $5.00 off of razors when you purchase $10.00 worth of razors' },
	  { value: val16[0], label: 'Buy $5.00 worth of ketchup or $5.00 worth of mustard, Save $2' },
	  { value: val17[0], label: 'Buy $5.00 worth of ketchup and $3.00 worth of mustard, Save $1' },
	  { value: val18[0], label: 'Buy $5.00 worth of chips, AND $2.00 worth of dip or $2.00 worth of soda, Get $2 Off' },
	  { value: val19[0], label: 'Buy $1.00 worth of chips OR $2.00 worth of dip OR $5.00 worth of soda, Get $2 Off' },
	  { value: val20[0], label: 'Buy $1.00 worth of chips and $2.00 worth of dip and $3.00 worth of soda, Get $3 Off' },
	  { value: val22[0], label: 'Buy product A and spend $10 on your order, and save $1 on A' },
	  { value: val24[0], label: 'Buy 24 cans and get 4 Cans Free.(GTIN Multiplier)' },
	  { value: val30[0], label: 'Buy 24 cans and get 4 Cans Free.(GTIN Multiplier)- Fail Case' },
	  { value: val25[0], label: 'Buy 5 Bottles of Ketchup but not toothpaste and get 2 bottle of ketchup free - Pass(Exclusion List)' },
	  { value: val26[0], label: 'Buy 5 Bottles of Ketchup but not toothpaste and get 2 bottle of ketchup free - Fail(Exclusion List)' },
	  { value: val27[0], label: 'For each 50 $ spent, get 5 $ off(Reducing Basket)' },
	  { value: val28[0], label: 'Buy 5 kg fish with PLU 3022, and get 5 $ off(Purchase Prefix Code)' },
	  { value: val29[0], label: 'Buy 24 cans and get 10% off on Total.(GTIN Multiplier with LookupCode)' }
];
*/
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const Form = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rezioroutput, setRezioroutput] = useState('Expected Output');
  const [isrezioroutput, setisRezioroutput] = useState(false);
  const [basket, setBasket] = useState('Please select a value from Dropdown');
  const [ButtonText, setButtonText] = useState('Validate Basket');
  const [basketLabel, setBasketLabel] = useState('');
  const [show, setShow] = useState(false);
  const [validcoupons, setValidcoupons] = useState([]);
  const [invalidcoupons, setInvalidcoupons] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showHeading, setShowHeading] = useState(false);
  const [discountincents, setDiscountincents] = useState("0");
  

  const handleChange = (e) => {
    setBasket(e.value );
	setBasketLabel(e.label );
	setShowDetails(false);
	setShowHeading(true);
	setRezioroutput('Expected Output');
	setButtonText("Validate Basket");
  };
  
 
  
  const showProcessing=() => {
	setShow(true);
	};

const hideProcessing=() => {
  setShow(false);
};

  const handleSubmit = (e) => {
    e.preventDefault();
	showProcessing();
	setButtonText("Validating Basket");
	setButtonText("Validating Basket..");
	setButtonText("Validating Basket....");
    // Here, you can perform actions with the submitted data (e.g., send it to a server)
	let data=""
	try{
	data = JSON.parse(JSON.stringify(basket));
	console.log(data);
	}catch(ex){
		setRezioroutput("Invalid Basket JSON " +ex.message);
		setisRezioroutput(false);
		hideProcessing();
		setButtonText("Basket Validated");
		return;
	}
	console.log(data);
	let config = {
	  method: 'post',
	  maxBodyLength: Infinity,
	  url: 'https://deviceapi.rezior.com/api/coupons/processBasket',
	  headers: { 
		'x-key': 'reziorisbest', 
		'Content-Type': 'application/json'
	  },
	  data : data
	};

	axios.request(config)
	.then((response) => {
	  console.log(JSON.stringify(response.data));
	  //setFormData({
		//message: JSON.stringify(response.data)
	  //});
	  var tempr=response.data;
	  setDiscountincents(tempr.data.discount);
	  let valc=[];
	  let ivalc=[];
	  for(var i=0;i<tempr.data.valid_coupons.length;i++){
		  
		  valc.push(tempr.data.valid_coupons[i].serialized_gs1);
	  }
	  for(var i=0;i<tempr.data.not_valid_coupons.length;i++){
		  ivalc.push(tempr.data.not_valid_coupons[i].serialized_gs1);
	  }
	  setValidcoupons(valc);
	  setInvalidcoupons(ivalc);
	  setRezioroutput(JSON.stringify(response.data, null, 4));
	  setisRezioroutput(true);
	  setShowDetails(true)
	  hideProcessing();
	  setButtonText("Basket Validated");
	})
	.catch((error) => {
	  setRezioroutput("Something went wrong " +error.message);
	  setisRezioroutput(false);
	  hideProcessing();
	  console.log(error);
	  setButtonText("Basket Validated");
	});
    // Reset the form after submission
	
	
	
	
  };

  
  return (
<div className="justify-content-center p-4" >
	<div style={{backgroundColor:"#E7F6FF"}} className="container justify-content-center w-75">	
		<div className="cols w-100">
		{/*<h2 className="text-center"> </h2>*/}
			<div className="justify-content-center"> Select Test Condition</div>

			<Select style={styledropdown} onChange={handleChange} components={animatedComponents} className="mb-5" options={options} />
				{/*{showHeading && <div className="m-2 d-flex p-2 align-middle">
				<h5>{basketLabel}</h5>
				</div>}*/}
			<div className="container d-flex justify-content-center">	
				<div className="cols" style={{width:"368.5px", height:"226px"}}>
					<div className="text-center" style={{padding: "0px 0px 4px 4px", height:"22px"}}>
					<label className="">Basket JSON</label>
					</div>
					<textarea 
					  style={{overflow:"hidden"}}
					  className="m-2 p-2 rounded-4 border-2"
					  id="message"
					  name="message" style={{ color: 'blue', height : 200, width: 300 }}
					  value={JSON.stringify(basket, null, 4)}
					/>
				</div>
				
				<div className="cols">
					<div className="text-center">
						<label className="">Response JSON</label>
					</div>
					<textarea
					  style={{overflow:"hidden"}}
					  className="m-2 p-2 rounded-4 border-2"
					  id="rezioroutput"
					  name="rezioroutput" style={{ color: 'green', height : 200, width: 300 }}
					  value={rezioroutput}
					  required
					  readOnly
					/>
				</div>
			</div>
		</div>
		
		<div className="cols w-100 p-4">
			<div style={{backgroundColor:"#295E74", height:"48px", radius:"8px", padding: "0px 24px 0px 24px", gap :"8px"}} type="button"  onClick={handleSubmit} className="p-2 text-center text-white text-center rounded-2">
				
				{ButtonText}
			</div>
		</div>
		
		
		{showDetails===true && 
		<div className="cols p-4">			
			<div className="bg-gradient text-white fw-bold align-middle" style={{height: 30, backgroundColor:"#295E74", padding:"16px", "border-radius": "0px 0px 4px 4px", "border": "0px 1px 1px 1px", "gap": "16px", height:"58px"}}>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
			  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
			</svg> Discount of {discountincents} Cents have been applied
			</div>

			
		</div>
		}
		
		<div className="cols">
		{basketLabel!="" && showDetails=== true &&
		<div className="p-2 text-white text-center">		
			  <Accordion>
				<AccordionSummary className="border" 
				  expandIcon={<ExpandMoreIcon />}
				  aria-controls="panel1a-content"
				  id="panel1a-header"
				>
				  <Typography className="text-center text-success justify-content-center">Explanation Summary</Typography>
				</AccordionSummary>
				<AccordionDetails className="text-black">
					<div className="container ">	
					    <div className="cols py-4">
						  <div>
							<label className="fw-bold">Selected Items in Basket </label>
						  </div>
						  <TableContainer className="py-2" component={Paper}>
							  <Table sx={{ minWidth: 500 }} aria-label="customized table">
								
								  <TableRow style={{backgroundColor:"#E7F6FF"}}>
									<StyledTableCell>GTIN</StyledTableCell>
									<StyledTableCell align="right">Quantity</StyledTableCell>
									<StyledTableCell align="right">UNIT</StyledTableCell>
									<StyledTableCell align="right">Price</StyledTableCell>
								  </TableRow>
								
								<TableBody>
								  {basket.basket.map((row) => (
									<StyledTableRow key={row.code}>
									  <StyledTableCell component="th" scope="row">
										{row.code}
									  </StyledTableCell>
									  <StyledTableCell align="right">{row.quantity}</StyledTableCell>
									  <StyledTableCell align="right">{row.unit}</StyledTableCell>
									  <StyledTableCell align="right">{row.price}</StyledTableCell>
									</StyledTableRow>
								  ))}
								</TableBody>
							  </Table>
						  </TableContainer>
						</div>
						<div className="cols py-4">
						  <div>
							<label className="fw-bold">Applied Coupons</label>
						  </div>
							<TableContainer className="py-2" component={Paper}>
							  <Table sx={{ minWidth: 500, paddingLeft: 10 }} aria-label="customized table">
								
								  <TableRow style={{backgroundColor:"#E7F6FF"}}>
									<StyledTableCell >Serialised GS1</StyledTableCell>
									<StyledTableCell align="center">Status</StyledTableCell>
									<StyledTableCell align="right">Action</StyledTableCell>
								  </TableRow>
								
								<TableBody>
								  {validcoupons.map((row) => (
									<StyledTableRow key={row}>
									
									  <StyledTableCell align="left">
										{row}
									  </StyledTableCell>
									  <StyledTableCell align="right">
										<button className="btn btn-success"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
										  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
										  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
										</svg> &nbsp;Success</button>
									</StyledTableCell>
									  <StyledTableCell align="right">
									  
									  {/*<Accordion>
											<AccordionSummary className="border" 
											  expandIcon={<ExpandMoreIcon />}
											  aria-controls="panel1a-content"
											  id="panel1a-header"
											>
											  <Typography className="text-center justify-content-center">Check Validation</Typography>
											</AccordionSummary>
											<AccordionDetails className="text-success">
											Hello Its good
											</AccordionDetails>
									  </Accordion>*/}
									  
									  <Button onClick={handleOpen}>Check Validation</Button>
										  <Modal
											open={open}
											style={{ overflow: 'scroll' }}
											onClose={handleClose}
											aria-labelledby="modal-modal-title"
											aria-describedby="modal-modal-description"
										  >
											<Box sx={style}>
											  <Typography className="text-success fw-bold" id="modal-modal-title" variant="h6" component="h2">
												Validation Check
											  </Typography>
											  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
												
												<div className="container d-flex justify-content-center">	
													<div className="cols">
													{isrezioroutput===true && JSON.parse(rezioroutput).data.valid_coupons.map((coupon) =>(
													<>
													  <div>
														<label className="fw-bold">Selected Items in Basket for Coupon {coupon.serialized_gs1} 
													 </label>
													  </div>
													  
													  <TableContainer component={Paper}>
														  <Table sx={{ minWidth: 500 }} aria-label="customized table">
															  <TableRow style={{backgroundColor:"#E7F6FF"}}>
																<StyledTableCell>GTIN</StyledTableCell>
																<StyledTableCell align="right">Quantity</StyledTableCell>
																<StyledTableCell align="right">UNIT</StyledTableCell>
																<StyledTableCell align="right">Price</StyledTableCell>
															  </TableRow>
															
															<TableBody>
															  {coupon.applied_to.map((row) => (
																<StyledTableRow key={row.code}>
																  <StyledTableCell component="th" scope="row">
																	{row.code}
																  </StyledTableCell>
																  <StyledTableCell align="right">{row.quantity}</StyledTableCell>
																  <StyledTableCell align="right">{row.unit}</StyledTableCell>
																  <StyledTableCell align="right">{row.price}</StyledTableCell>
															
																</StyledTableRow>
															
															  ))}
															</TableBody>
														  </Table>
													  </TableContainer>
													  
													  {coupon.applied_to.length===0 &&
													  <u><small class="p-2 text-muted text-success">Applied to Total Transaction value and not to GTINs as Purchase Req Code is 2</small></u>
													  }
													  
													  <div className="cols text-success">
														<div className="fw-bold mt-2">Master Offer details </div><pre style={{height:150}} className="border border-2 rounded-4"> {JSON.stringify(coupon.mofDetails, null , 4)}</pre>
													  </div>

													  </>
													  
													  
													 
													  ))}
													</div>
												</div>
											  </Typography>
											</Box>
										  </Modal>
									  
									  
									  
									  
									  </StyledTableCell>
									</StyledTableRow>
								  ))}
								</TableBody>
							  </Table>
							</TableContainer>
						</div>
					</div>
						
						<div className="container">
						<div className="cols py-4">
						<div>
							<label className="fw-bold">Rejected Coupons</label>
						  </div>
						
						<TableContainer className="py-2" component={Paper}>
							  <Table sx={{ minWidth: 500, paddingLeft: 10 }} aria-label="customized table">
								
								  <TableRow style={{backgroundColor:"#E7F6FF"}}>
									
									<StyledTableCell >Serialised GS1</StyledTableCell>
									<StyledTableCell align="right">Status</StyledTableCell>
								  </TableRow>
								
								<TableBody>
								  {invalidcoupons.map((row) => (
									<StyledTableRow key={row}>
									
									  <StyledTableCell component="th" scope="row" align="left">
										{row}
									  </StyledTableCell>
									  <StyledTableCell align="right">
										  <button className="btn btn-danger"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
										  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
										</svg> &nbsp;Rejected</button>
										
									</StyledTableCell>
									</StyledTableRow>
								  ))}
								</TableBody>
							  </Table>
							</TableContainer>
							</div>
							<div className="cols">&nbsp;&nbsp;&nbsp;</div>
							
						
						
					</div>
				</AccordionDetails>
			  </Accordion>
		</div>}
		</div>
	</div> 
</div>
  );

};

export default Form;
